import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from "gatsby-plugin-image";

import * as styles from './Hero.module.scss';
import CountSignatures from "./CountSignatures";

const Hero = ({left, logo, right, channel, backgroundClass}) => {
    const leftBox = left ? <p className={[styles.title, styles.textRight, styles.colorDark].join(' ')}>{left}</p> : "";
    const rightBox = right ? <p className={[styles.title, styles.textLeft].join(' ')}>{right}</p> : "";

    const counter = channel ? <CountSignatures channel={channel} /> : "";

    const background = backgroundClass ?  backgroundClass : "default-background";

    return (
        <div className={[styles.container + ' ' + background]} id="#">
            <div className={styles.wrap}>
                <div className={styles.content}>
                    { leftBox }
                    <GatsbyImage className={styles.logo} image={logo} imgStyle={{ objectFit: 'contain' }} alt="Logo Solo Preto e Indígena"/>
                    { rightBox }
                </div>
                { counter }
            </div>
        </div>
    );
}

Hero.propTypes = {
    channel: PropTypes.string,
    left: PropTypes.string,
    logo: PropTypes.object,
    right: PropTypes.string,
    backgroundClass: PropTypes.string,
};

export default Hero;
